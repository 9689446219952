<template>
	<div id="fields-import">
		<h1>Associer client / commercial</h1>
		<p>Cette fonctionnalité permet d'associer un client à son commercial. <br />A faire si vous ajouter un nouveau client par exemple.</p>
		<button @click="associate()">Lancer l'association</button>

		<h1>Calculer le classement des suspects</h1>
		<p>Cette fonctionnalité permet de calculer le classement des suspects</p>
		<button @click="calculateSuspects()">Calculer le classement</button>

		<h1>Import de données</h1>
		<p>
			Cette fonctionnalité permet d'importer / modifier les données pour certains client depuis un fichier JSON. <br />Ne lancer que si vous êtes à l'origine du fichier
			JSON.
		</p>
		<button @click="runimport()">Lancer le remplacement des données</button>

		<h1>Télécharger les contrats manquants</h1>
		<p>Cette fonctionnalité permet de ré-télécharger les contrats signés échoués pour les MIP et MAP</p>
		<button @click="importMissingPDF()">Lancer la récupération des contrats signés</button>

		<h1>Envoyer les mails non envoyés</h1>
		<p>
			Cette fonctionnalité permet d'envoyer au client le PDF du contrat signé pour là où cela à échoué.
		</p>
		<button @click="sendFinalMail()">Lancer l'envoi des emails</button>

		<h1>Calculer le nombre de visites</h1>
		<p>
			Cette fonctionnalité permet de mettre à jour le nombre de visites en fonction de la classe de partenaire
		</p>
		<button @click="correctionVisites()">Lancer le calculateur de visites</button>
	</div>
</template>
<script>
var Airtable = require("airtable");
var base = new Airtable({ apiKey: process.env.VUE_APP_AIRTABLEAPI }).base(process.env.VUE_APP_AIRTABLEBASE);
import dataJSON from "../assets/json/data.json";
import axios from "axios";
export default {
	name: "fields",
	components: {},
	data() {
		return {
			data: dataJSON.data
		};
	},
	methods: {
		associate() {
			base("clients")
				.select({
					maxRecords: 9999,
					view: "base_clients sans commerciaux",
					pageSize: 10
				})
				.eachPage(
					function page(records, fetchNextPage) {
						records.forEach(function(record) {
							if (!record.fields.commercial_attache) {
								let commercial;
								base("utilisateurs")
									.select({
										maxRecords: 1,
										view: "liste_des_utilisateurs",
										filterByFormula: "({secteur} = " + record.fields["Secteur commercial"] + ")"
									})
									.eachPage(
										function page(res, fetchNextPage) {
											res.forEach(function(item) {
												commercial = item.id;
											});
											fetchNextPage();
										},
										function done(err) {
											if (err) {
												console.error(err);
												return;
											}
											base("clients").update(
												[
													{
														id: record.id,
														fields: {
															commercial_attache: [commercial]
														}
													}
												],
												function(err) {
													if (err) {
														console.error(err);
														return;
													}
												}
											);
										}
									);
							}
						});
						fetchNextPage();
					},
					function done(err) {
						if (err) {
							console.error(err);
							return;
						}
					}
				);
		},
		importMissingPDF() {
			base("clients")
				.select({
					maxRecords: 9999,
					view: "base_clients signés",
					pageSize: 10
				})
				.eachPage(
					function page(records, fetchNextPage) {
						records.forEach(function(record) {
							if (record.fields["Classe de partenaire"].includes("Partenaire") || record.fields["Classe de partenaire"].includes("Proactif")) {
								if (!record.fields.url_pdf_signe && record.fields.id_procedure_signature) {
									this.$store
										.dispatch("downloadSignedDocument", {
											procedure: record.fields["id_procedure_signature"],
											client: record
										})
										.then(res => {});
								}
							}
						});
						fetchNextPage();
					},
					function done(err) {
						if (err) {
							console.error(err);
							return;
						}
					}
				);
		},
		sendFinalMail() {
			base("clients")
				.select({
					maxRecords: 9999,
					view: "base_clients signés",
					pageSize: 10
				})
				.eachPage(
					function page(records, fetchNextPage) {
						records.forEach(function(record) {
							if (record.fields["Classe de partenaire"].includes("Partenaire") || record.fields["Classe de partenaire"].includes("Proactif")) {
								if (!record.fields.pdf_signe_envoye) {
									console.log("mail non envoyé");
									let params = new URLSearchParams();
									let file = record.fields.url_pdf_signe;
									let client = record.fields["Email signataire"];
									let commercial = record.fields["email_commercial"];
									if (file && record.fields.url_pdf_signe[0].url) {
										const name =
											"PAC_2025_" +
											record.fields["Numéro client"].trim() +
											"-" +
											record.fields["Société"] +
											"_" +
											record.fields["Nom signataire"] +
											".pdf";
										params.append("commercial", commercial);
										params.append("client", client);
										params.append("file", record.fields.url_pdf_signe[0].url);
										params.append("filename", name);
										params.append("service", "finalmailcorrectif");
										axios.post("https://dev-serveur.fr/mailjet/viessmann/api.php", params)
											.then(res => {
												base("clients").update([
													{
														id: record.id,
														fields: {
															pdf_signe_envoye: true
														}
													}
												]);
											})
											.catch(err => {
												console.log(err);
											});
									} else {
										console.log("Ignoré car pas de fichier");
									}
								}
							}
						});
						fetchNextPage();
					},
					function done(err) {
						if (err) {
							console.error(err);
							return;
						}
					}
				);
		},
		runimport() {
			this.data.forEach((item, index) => {
				setTimeout(() => {
					base("clients")
						.select({
							maxRecords: 1,
							view: "base_clients",
							filterByFormula: "({Numéro client} = " + item["Numéro client"] + ")"
						})
						.eachPage(
							function page(records, fetchNextPage) {
								records.forEach(function(record) {
									base("clients").update(
										[
											{
												id: record.id,
												fields: {
													Thermodynamique: parseInt(item["Thermodynamique"])
												}
											}
										],
										function(err) {
											if (err) {
												console.error(err);
												return;
											}
										}
									);
								});
								fetchNextPage();
							},
							function done(err) {
								if (err) {
									console.error(err);
									return;
								}
							}
						);
				}, index * 100);
			});
		},
		calculateSuspects() {
			base("base_suspects")
				.select({
					maxRecords: 9999,
					view: "base_suspects",
					pageSize: 10
				})
				.eachPage(
					function page(records, fetchNextPage) {
						records.forEach(function(record) {
							let potentiel = parseInt(record.fields.potentiel_ca_calcule) || 0;
							let avis_credit = parseInt(record.fields.avis_credit_conseille) || 0;
							let rating = parseInt(record.fields.rating) || 0;
							let classement = 0;
							if (rating <= 1) {
								classement = 0;
							} else {
								classement = Math.round((potentiel / 1000 + avis_credit / 333) * rating);
							}
							if (!record.fields.classement) {
								base("base_suspects").update(
									[
										{
											id: record.id,
											fields: {
												classement: classement
											}
										}
									],
									function(err) {
										if (err) {
											console.error(err);
											return;
										}
									}
								);
							}
						});
						fetchNextPage();
					},
					function done(err) {
						if (err) {
							console.error(err);
							return;
						}
					}
				);
		},
		correctionVisites() {
			base("clients")
				.select({
					maxRecords: 9999,
					view: "base_clients",
					pageSize: 10
				})
				.eachPage(
					function page(records, fetchNextPage) {
						records.forEach(function(record) {
							let nb_visites = 0;
							if (record.fields["Classe de partenaire"] === "Basic (PAR)") {
								nb_visites = 0;
							} else if (record.fields["Classe de partenaire"] === "Partenaire (MAP)") {
								nb_visites = 8;
							} else if (record.fields["Classe de partenaire"] === "Prospect (EK)") {
								nb_visites = 24;
							} else if (record.fields["Classe de partenaire"] === "Proactif (MIP)") {
								nb_visites = 12;
							} else if (record.fields["Classe de partenaire"] === "Proactif ÉcoSystème (MI+)") {
								nb_visites = 16;
							}
							base("clients").update(
								[
									{
										id: record.id,
										fields: {
											"Objectif visites": nb_visites
										}
									}
								],
								function(err) {
									if (err) {
										console.error(err);
										return;
									}
								}
							);
						});
						fetchNextPage();
					},
					function done(err) {
						if (err) {
							console.error(err);
							return;
						}
					}
				);
		}
	}
};
</script>

<style lang="scss" scoped>
#fields-import {
	margin: 0px auto;
	width: 1200px;
	font-size: 12px;
	padding: 50px;
	min-height: 100vh;
	h1 {
		margin-top: 100px;
	}
	p {
		margin: 15px 0 0;
		font-size: 16px;
		font-weight: 300;
		line-height: 22px;
	}
	button {
		background: $rouge;
		color: white;
		border: none;
		border-radius: 4px;
		padding: 10px 30px;
		text-transform: uppercase;
		font-weight: 500;
		letter-spacing: 0.5px;
		margin: 20px 0 0 0;
		cursor: pointer;
		&:hover {
			background: darken($rouge, 5%);
		}
	}
}
</style>
